<template>
  <div class="flex-none flex-no-wrap w-32 md:w-60 xl:w-72">
    <div class="flex flex-col items-center justify-center">
      <a v-if="getDownloadUrl" :href="getDownloadUrl" target="_blank">
        <img
          v-if="badge.img"
          :src="getImage"
          :alt="getAlt"
          :class="getSizeClasses"
          class="rounded-md"
        />
      </a>
      <img
        v-if="badge.img && !getDownloadUrl"
        :src="getImage"
        :alt="getAlt"
        :class="getSizeClasses"
        class="rounded-md"
      />
      <div
        v-if="badge.name"
        class="text-center text-sm font-semibold text-gray-500 w-2/3 pt-2"
        v-html="badge.name"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CertificateBadge from "@/types/CertificateBadge";

@Options({
  props: {
    badge: {},
  },
})
export default class CareerListItem extends Vue {
  badge!: CertificateBadge;

  get getImage(): string {
    return "/img/certificates/" + this.badge.img;
  }

  get getDownloadUrl(): string | undefined {
    return this.badge.downloadUrl || undefined;
  }

  get getAlt(): string {
    return (
      this.badge.img.replace(/\.[^/.]+$/, "").replace(/_/g, " ") +
      " certificate"
    );
  }

  get getSizeClasses(): string {
    return (
      this.badge.sizeClasses || "w-32 h-32 md:w-60 md:h-60 xl:w-72 xl:h-72"
    );
  }
}
</script>
