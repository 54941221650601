
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    certificate: {},
  },
})
export default class CertificateListItem extends Vue {
  certificate!: unknown;
}
