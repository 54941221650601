
import { Options, Vue } from "vue-class-component";
import CertificateBadge from "@/types/CertificateBadge";

@Options({
  props: {
    badge: {},
  },
})
export default class CareerListItem extends Vue {
  badge!: CertificateBadge;

  get getImage(): string {
    return "/img/certificates/" + this.badge.img;
  }
  get getAlt(): string {
    return (
      this.badge.img.replace(/\.[^/.]+$/, "").replace(/_/g, " ") +
      " certificate"
    );
  }
  get getSizeClasses(): string {
    return (
      this.badge.sizeClasses || "w-32 h-16 md:w-60 md:h-32 xl:w-72 xl:h-40"
    );
  }
}
