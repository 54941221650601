
import { Options, Vue } from "vue-class-component";
import CertificateListItem from "@/components/CertificateListItem.vue"; // @ is an alias to /src
import CertificateBadgeItem from "@/components/CertificateBadgeItem.vue"; // @ is an alias to /src
import CertificateBadgeWithScoreItem from "@/components/CertificateBadgeWithScoreItem.vue"; // @ is an alias to /src

@Options({
  components: {
    CertificateListItem,
    CertificateBadgeItem,
    CertificateBadgeWithScoreItem,
  },
})
export default class Certificates extends Vue {
  badges = {
    awsBadges: [
      {
        img: "aws_certified_data_engineer_associate_badge.png",
        name: "2024-2027",
        sizeClasses: "",
      },
      {
        img: "aws-certified-developer-associate-renewed-2024.png",
        name: "2024-2027<br />(renewed)",
        sizeClasses: "",
      },
      {
        img: "aws_associate_dev_2021_badge.png",
        name: "2021-2024",
        sizeClasses: "",
      },
      {
        img: "aws_certified_solutions_architect_associate_badge.png",
        name: "2021-2026",
        sizeClasses: "",
      },
      {
        img: "aws_cloud_practitioner_2021_badge.png",
        name: "2021-2027",
        sizeClasses: "",
      },
    ],
    awsSpecialtyBadges: [
      {
        img: "aws_certified_solutions_architect_professional_badge.png",
        name: "2023-2026",
        sizeClasses: "",
      },
      {
        img: "aws_certified_security_specialty_badge.png",
        name: "2022-2025",
        sizeClasses: "",
      },
      {
        img: "aws_certified_database_specialty_badge.png",
        name: "2023-2026",
        sizeClasses: "",
      },
    ],
    awsPartnerBadges: [
      {
        img: "aws-partner-accreditation-technical.png",
        name: "2022",
        sizeClasses: "",
      },
    ],
    awsCourseCompletionCertificates: [
      {
        img: "planning-large-scale-data-migrations-to-AWS.png",
        downloadUrl:
          "/docs/certificates/planning-large-scale-data-migrations-to-AWS.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "fundamentals-of-analytics-on-AWS–part2.png",
        downloadUrl:
          "/docs/certificates/fundamentals-of-analytics-on-AWS–part2.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "fundamentals-of-analytics-on-AWS–part1.png",
        downloadUrl:
          "/docs/certificates/fundamentals-of-analytics-on-AWS–part1.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "202309-aws-well-architected-course-1-completion.png",
        downloadUrl:
          "/docs/certificates/202309-aws-well-architected-course-1-completion.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "202309-aws-well-architected-course-advanced-2-completion.png",
        downloadUrl:
          "/docs/certificates/202309-aws-well-architected-course-advanced-2-completion.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "20220928_aws_partner_security_best_practices_technical_training.jpg",
        downloadUrl:
          "/docs/certificates/20220928_aws_partner_security_best_practices_technical_training_certificate_of_completion.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "20220906_aws_training_certification_of_completion_aws_partner_accreditation_technical.png",
        downloadUrl:
          "/docs/certificates/10715_5_1377922_1662473909_AWS_Skill_Builder_Course_Completion_Certificate.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "20220815_aws_training_certification_of_completion_billing_and_cost_management.jpg",
        downloadUrl:
          "/docs/certificates/129_3_1377922_1660567205_AWS_Course_Completion_Certificate.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
      {
        img: "20220815_aws_training_certification_of_completion_shared_responsibility.jpg",
        downloadUrl:
          "/docs/certificates/155_3_1377922_1660567615_AWS_Course_Completion_Certificate.pdf",
        sizeClasses: "w-32 h-24 md:w-60 md:h-48 xl:w-72 xl:h-52",
      },
    ],
    otherBadges: [
      {
        img: "envoy-fundamentals-by-solo-io-badge.png",
        sizeClasses: "w-32 h-32 md:w-48 md:h-48 xl:w-52 xl:h-52",
      },
    ],
    awsCerts: [
      {
        img: "aws_certified_solutions_architect_associate_cert.png",
        sizeClasses: "",
      },
      {
        img: "aws_associate_dev_2021_cert.png",
        sizeClasses: "",
      },
      {
        img: "aws_cloud_practitioner_2021_cert.png",
        sizeClasses: "",
      },
    ],
    pluralSightScores: [
      {
        img: "pluralsight_skill_iq_expressjs.jpeg",
        sizeClasses: "",
        name: "ExpressJs",
        score: {
          value: 226,
          max: 300,
        },
      },
      {
        img: "pluralsight_skill_iq_security_fundamentals.jpeg",
        sizeClasses: "",
        name: "Security",
        nameHiddenSmall: "Fundamentals",
        score: {
          value: 158,
          max: 300,
        },
      },
      {
        img: "pluralsight_skill_iq_scrum_master.jpeg",
        sizeClasses: "",
        name: "Scrum",
        nameHiddenSmall: "Master",
        score: {
          value: 166,
          max: 300,
        },
      },
    ],
  };
  certificates = [
    {
      category: "cloud",
      title: "AWS Certified Solutions Architect – Professional",
      issuer: "AWS",
      url: "https://www.credly.com/badges/93673892-88d7-4aa0-84c1-e66df7961c73",
    },
    {
      category: "cloud",
      title: "AWS Certified Security – Specialty",
      issuer: "AWS",
      url: "https://credly.com/badges/5aea3e56-9a1e-4066-8e72-770b523e92f1",
    },
    {
      category: "cloud",
      title: "AWS Certified Database – Specialty",
      issuer: "AWS",
      url: "https://credly.com/badges/92e08cd1-edee-4a27-a8fb-6e8f07148afc",
    },
    {
      category: "cloud",
      title: "AWS Certified Data Engineer – Associate",
      issuer: "AWS",
      url: "https://credly.com/badges/9c3a726c-3ff9-44ba-8ff5-8605dbf65a12",
    },
    {
      category: "cloud",
      title: "AWS Certified Solutions Architect – Associate",
      issuer: "AWS",
      url: "https://credly.com/badges/eba2869b-ed52-4e8b-a2a7-9d3d1cc65ac7",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer – Associate (renewed 2024)",
      issuer: "AWS",
      url: "https://credly.com/badges/bd874d15-a549-4cd1-8d1c-19e52ed9f23e",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer – Associate",
      issuer: "AWS",
      url: "https://credly.com/badges/c30a1484-e2a6-4da7-8591-5c4bacba3341",
    },
    {
      category: "cloud",
      title: "AWS Certified Cloud Practitioner",
      issuer: "AWS",
      url: "https://credly.com/badges/26346a2d-ff71-41d7-898b-35c09ad5ad08",
    },
    {
      category: "cloud",
      title: "AWS Partner: Accreditation (Technical)",
      issuer: "AWS",
      url: "https://credly.com/badges/3bad6e45-e310-4170-ace4-0e896e4000b0",
    },
    {
      category: "cloud",
      title: "Fundamentals for Envoy",
      issuer: "Solo.io",
      url: "https://credly.com/badges/5e7d7d00-efc1-4401-972b-84badaf4bcdc",
    },
    {
      category: "cloud",
      title:
        "Kubernetes Administrator: Kubernetes Fundamentals for Administrators",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/835c8b92-2c01-497c-b7b7-de8614f6d16d",
    },
    {
      category: "cloud",
      title:
        "AWS Associate Solutions Architect 2020: Stack Deployment & Caching",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/696ac1fe-f2b1-41e8-ac8b-f5934f8dc0b4",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Monitoring",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/af69c15b-123e-4ec9-89ea-2eff3a3ad3ec",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: AWS Developer Options",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/6c9b85a6-8691-4da2-b96f-62b866442632",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: AWS Cost Management",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/0c21b6a0-f5ef-4a93-92de-bc3f887c2933",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Security",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/7fdf0d3d-6b6a-4d3c-a09c-577d40a3faf1",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Application Scalability",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/18137f62-93f8-4bd1-9c57-55214157ef82",
    },
    {
      category: "cloud",
      title:
        "AWS Associate Solutions Architect 2020: Identity & Access Management",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/8d532ded-2264-4d41-ae9e-cd82c4c0c774",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Database Solutions",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/fddd771a-d339-4b37-9395-a96fea4b11dc",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: EC2 Instance Management",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/89bedff8-4685-4336-8e91-ff0c2666d745",
    },
    {
      category: "cloud",
      title:
        "AWS Associate Solutions Architect 2020: Network Security & Name Resolution",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/062430fd-2bda-48ab-bc53-acb407f1b19c",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Network Options",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/8cb7ce19-2037-4e02-b6c2-5678059c1b32",
    },
    {
      category: "cloud",
      title:
        "AWS Associate Solutions Architect 2020: Storage Security & Resiliency",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/6a37a6ce-753b-4ff5-9375-1ca8aca7a1a3",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Storage Solutions",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/92e99181-5a20-4132-bc0d-1443f23e90f3",
    },
    {
      category: "cloud",
      title: "AWS Associate Solutions Architect 2020: Management & Migration",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/0eca4735-af05-4cfc-befc-860ad5ef0dea",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Optimizing AWS",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/20e8172b-4211-45f5-a2e4-f10a84400e5a",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Troubleshooting & Monitoring on AWS",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/0921d8aa-c176-4de0-b2f6-e82b59a82927",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: AWS Security & Encryption",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/762fad4f-5cdc-4eff-8f60-c0e6166a571a",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Stateless Applications",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/d4b30372-adcf-4517-8b04-4815b2ec57f4",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: DynamoDB NoSQL Database",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/8e704562-f3cc-4fc6-8cf8-9d04b6f758ef",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Serverless Applications",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/89d70147-324d-4392-81dd-f492b3284b1c",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Serverless Compute Services",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/e44242ef-a167-4e96-a9ca-8ca987a855ca",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Application Integration & Microservices",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/a6ef4607-a413-43ff-a5da-838cccfa5b81",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Configuration as Code",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/a8b4b254-69c0-473d-8c36-67742b859612",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: AWS CloudFormation",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/898e00b9-087d-4bd0-9947-a8c3da137c0f",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: CI/CD in AWS",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/5161ee66-6da4-4a00-b1be-b7244c2e3935",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: AWS Elastic Beanstalk (EB)",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/8d4df62f-0782-4512-8964-4705b2ce1ad1",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: AWS Database Services",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/0bbad2d8-9617-4971-a615-988032c8d396",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Storage Services",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/3a02849c-7431-4f3e-ab39-0b832f6a7438",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Interacting with AWS Cloud Services",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/100c943c-3fe9-4816-be3a-a8af5c75aa5b",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Amazon Elastic Compute Cloud",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/301f3919-3804-4a8c-af43-a0ad97898355",
    },
    {
      category: "cloud",
      title: "AWS Developer Associate: Identity & Access Management",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/6ffaad6a-6641-4ae1-95fc-abaaa117a7ac",
    },
    {
      category: "cloud",
      title: "AWSAWS Cloud Practitioner 2020: Technology Support Resources",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/69d76cc8-3491-4612-abb5-ed7616c8d33b",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Core Services",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/27faaf15-4d67-47f7-ab5e-ee43de62f638",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Cloud Deployment & Operation",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/d080a4d0-32a9-4567-9035-c6c1000831da",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Security Support Resources",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/db938b71-87ba-43ce-b523-fb4cdc2caf19",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Access Management",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/1dfe9121-118d-4c22-b4d6-a0fd8ecc213f",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Cloud Security & Compliance",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/539dc40e-1882-4b41-b928-fbfc396d09e8",
    },
    {
      category: "cloud",
      title:
        "AWS Cloud Practitioner 2020: Cloud Architecture Design Principles",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/88f05579-a79e-4be7-b96f-70170045add2",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Cloud Economics",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/d1e00e63-912c-441d-8b27-074943229d38",
    },
    {
      category: "cloud",
      title: "AWS Cloud Practitioner 2020: Cloud & the Value Proposition",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/a1527d80-0057-426e-998a-4560d6d88a94",
    },
    {
      category: "cloud",
      title: "Serverless Solutions: Evolving a Serverless Mindset",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/6f531982-23d7-49bd-a38b-aa0eb0da1d75",
    },
    {
      category: "security",
      title: "Secure Application Architecture & IAM",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/ae3ae0ae-41f4-4bac-917a-94f08309955a",
    },
    {
      category: "security",
      title: "OWASP: Top 10 List Items",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/49af29e1-24d2-4145-97c9-da9f19558c01",
    },
    {
      category: "security",
      title: "API Security",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/54d44f46-75d4-4a82-a695-17fb1ab478bf",
    },
    {
      category: "security",
      title: "Application Security Awareness & Validation",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/1eaa79a9-fd4c-4f46-a6e8-78554974ceb6",
    },
    {
      category: "leadership",
      title: "How Culture Impacts Communication",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/e86bece2-3c67-4815-8dd2-39b7affe7bac#gs.9vxfba",
    },
    {
      category: "leadership",
      title: "Using Strategic Thinking to Consider the Big Picture",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/d70e8c68-adef-4f1f-b0e4-15a877dc4db9",
    },
    {
      category: "leadership",
      title: "Solve Problems Using Systems Thinking in the Workplace",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/78397360-37ec-4952-97ac-2202c5555473",
    },
    {
      category: "leadership",
      title: "Confronting Workplace Conflict",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/1115d7f8-59cf-420d-9e89-026851485e5b",
    },
    {
      category: "leadership",
      title: "Resolving Workplace Conflict",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/fb218870-94fb-4c80-a4e1-8d2c38a75feb",
    },
    {
      category: "leadership",
      title: "Handling Team Conflict",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/7781d9e0-fd34-4e21-8680-77d23c33d36e",
    },
    {
      category: "leadership",
      title: "Body Language for Leaders",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "programming",
      title: "Web Programming in Go: Web Development Basics",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/b9c24e5a-362b-4acb-86d6-47bbfe762c63",
    },
    {
      category: "programming",
      title: "Go Language Basics",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/de624a80-1e57-4652-a7a0-2d087fb0d50e",
    },
    {
      category: "programming",
      title: "Functions in Go",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/30dcf1c7-dd50-4caf-87a2-f5abd39f2082",
    },
    {
      category: "programming",
      title: "Introduction to Reactive Programming",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/07b7dc33-a7f4-411a-b0a7-7e2e44ef4c39",
    },
    {
      category: "programming",
      title: "Introduction to RabbitMQ",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/ac6b48ea-86c9-4edd-afc3-6b06b7450d06",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer Bootcamp: Session 1 Replay",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/c99242a6-cda5-4897-ace7-87da4fff9925",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer Bootcamp: Session 2 Replay",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/62ab27d6-f546-4312-9839-76bbfa4dbe35",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer Bootcamp: Session 3 Replay",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/4afc4af0-dfdd-4b9a-94b2-12fca702bead",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer Bootcamp: Session 4 Replay",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/865a9b95-ea8d-4940-b2ff-db8fa888469c",
    },
    {
      category: "cloud",
      title: "AWS Certified Developer Bootcamp: Session 5 Replay",
      issuer: "Percipio @ Skillsoft",
      url: "https://skillsoft.digitalbadges.skillsoft.com/2b91d4fb-8137-4737-a024-39a2e63d2a1a",
    },
    {
      category: "Expired",
      title: "Linux Professional Institute Certificate: LPIC-1",
      issuer: "Laitec Sharif (non-official)",
      url: null,
    },
    {
      category: "Expired",
      title: "Linux Professional Institute Certificate: LPIC-2",
      issuer: "Laitec Sharif (non-official)",
      url: null,
    },
    {
      category: "Expired",
      title: "Oracle Certified Associate (OCA)",
      issuer: "Asre Danesh Afzar (non-official)",
      url: null,
    },
    {
      category: "Expired",
      title: "We Security: OAuth and OpenID Connect",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "Angular2: Widget-Based Architectures",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "DevOps Foundations: Infrastructure Automation",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "Get Ahead in PHP: PHP7 Features and Frameworks",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "Learning AngularJS 2 (2016)",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "PHP 7 New Features",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "Creating Secure PHP Websites",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "Strict Data Types in PHP",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "MVC Frameworks for Building PHP Web Applications",
      issuer: "LinkedIn",
      url: null,
    },
    {
      category: "Expired",
      title: "Design Patterns in PHP",
      issuer: "LinkedIn",
      url: null,
    },
  ];
}
