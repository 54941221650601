<template>
  <div class="flex-none flex-no-wrap" v-if="badge.img">
    <img
      v-if="badge.img"
      :src="getImage"
      :alt="getAlt"
      :class="getSizeClasses"
      class="rounded-md"
    />
    <div
      class="flex text-gray-500 font-bold md:font-semibold text-sm md:text-base"
    >
      <div class="flex-shrink-0 flex-grow font-nunito-sans">
        {{ badge.name }}
        <span
          class="hidden md:inline-flex"
          v-if="badge.nameHiddenSmall"
          v-html="badge.nameHiddenSmall"
        ></span>
      </div>
      <div class="flex-shrink-0 font-mono text-sm text-gray-400">
        {{ badge.score.value
        }}<span class="hidden md:inline-flex"> /{{ badge.score.max }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CertificateBadge from "@/types/CertificateBadge";

@Options({
  props: {
    badge: {},
  },
})
export default class CareerListItem extends Vue {
  badge!: CertificateBadge;

  get getImage(): string {
    return "/img/certificates/" + this.badge.img;
  }
  get getAlt(): string {
    return (
      this.badge.img.replace(/\.[^/.]+$/, "").replace(/_/g, " ") +
      " certificate"
    );
  }
  get getSizeClasses(): string {
    return (
      this.badge.sizeClasses || "w-32 h-16 md:w-60 md:h-32 xl:w-72 xl:h-40"
    );
  }
}
</script>
