<template>
  <li class="leading-loose md:text-justify list-inside">
    <span class="text-sm md:text-md font-semibold">{{
      certificate.title
    }}</span>
    <span class="hidden md:inline mx-2 font-bold text-xs">-</span>
    <br class="md:hidden" />
    <span class="text-sm text-gray-500 font-nunito-sans">{{
      certificate.issuer
    }}</span>
    <span v-if="certificate.url" class="hidden md:inline mx-2 font-bold text-xs"
      >-</span
    >
    <a
      v-if="certificate.url"
      :href="certificate.url"
      target="_blank"
      class="hidden md:inline hover:text-gray-400 no-underline"
      >verify</a
    >
  </li>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    certificate: {},
  },
})
export default class CertificateListItem extends Vue {
  certificate!: unknown;
}
</script>
